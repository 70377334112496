import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/shared/interfaces';
import { environment } from '../../../../environments/environment';
import { ListKdaConfigurationResponse } from '../interfaces/responses';
import { Observable, map } from 'rxjs';
import { ModifyKdaConfigurationRequestPayload } from '../interfaces/requests';

@Injectable()
export class KdaConfigurationService {
  constructor(private readonly httpClient: HttpClient) {}
  /**
   * Lists configurations.
   *
   * @param tenant The unique identifier of the selected tenant.
   *
   * @return an array of configurations, because configurations are specified dynamically by entities. {@link KdaOfferEntityEnum}
   */
  public listConfigurations(tenant: string): Observable<ListKdaConfigurationResponse[]> {
    return this.httpClient
      .get<
        ApiResponse<ListKdaConfigurationResponse[]>
      >(`${environment.apiBasePath}/v1/kda/configuration`, { params: { tenant } })
      .pipe(map(response => response.payload));
  }

  /**
   * Updates the KDA configurations.
   *
   * @param newConfigurationValues An array of values which are updated. Only need to send
   * the properties which we want changed, but the entity is required for each configuration object.
   * @param tenant The unique identifier of the selected tenant.
   *
   *  @return an array of configurations.
   */
  public updateConfigurations(
    newConfigurationValues: ModifyKdaConfigurationRequestPayload[],
    tenant: string
  ): Observable<ListKdaConfigurationResponse[]> {
    return this.httpClient
      .patch<
        ApiResponse<ListKdaConfigurationResponse[]>
      >(`${environment.apiBasePath}/v1/kda/configuration`, { payload: newConfigurationValues }, { params: { tenant } })
      .pipe(map(response => response?.payload));
  }
}
