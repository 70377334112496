/* eslint-disable @typescript-eslint/naming-convention */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MeritOrderResponse, MeritOrderResponseMeta } from '../interfaces/responses';
import { ApiResponse } from '../../../shared/interfaces';
import { environment } from '../../../../environments/environment';

@Injectable()
export class MeritOrderService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Lists merit orders by the given parameters.
   *
   * @param params Contains the business day, the reserve type, the direction and the tenant identifier.
   */
  public listMeritOrders(params: {
    businessDay: string;
    reserveType: string;
    direction: string;
    tenant: string;
  }): Observable<ApiResponse<MeritOrderResponse[], MeritOrderResponseMeta>> {
    const { businessDay, reserveType, direction, tenant } = params;

    return this.http.get<ApiResponse<MeritOrderResponse[], MeritOrderResponseMeta>>(
      `${environment.apiBasePath}/v1/eda/merit-order`,
      {
        params: {
          business_day: businessDay,
          reserve_type: reserveType,
          direction,
          tenant,
        },
      }
    );
  }
}
