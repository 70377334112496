import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../shared/interfaces';
import { environment } from '../../environments/environment';
import { map, Observable } from 'rxjs';
import { ListAssignedModulesResponse } from '../shared/interfaces/responses';
import { BossModuleAccess, BossModuleName } from '../shared/enums';

@Injectable()
export class BossAccessService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Lists the assigned modules by the selected tenant identifier.
   *
   * @param tenantIdentifier The unique selected tenant identifier.
   */
  public listAssignedModules(tenantIdentifier: string): Observable<ListAssignedModulesResponse[]> {
    return this.httpClient
      .get<
        ApiResponse<ListAssignedModulesResponse[]>
      >(`${environment.apiBasePath}/v1/boss/tenant-assignment/${tenantIdentifier}`)
      .pipe(map(response => response.payload));
  }

  /**
   * Assigns a BOSs access to a selected tenant.
   *
   * @param tenantIdentifier The unique selected tenant identifier.
   * @param payload The payload to give a BOSs access to a tenant.
   */
  public assignBossAccess(
    tenantIdentifier: string,
    payload: {
      entity: string;
      module: BossModuleName;
      access: BossModuleAccess;
    }
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiBasePath}/v1/boss/tenant-assignment/${tenantIdentifier}`,
      payload
    );
  }

  /**
   * Deletes a selected BOSs access.
   *
   * @param params.tenantIdentifier The selected unique tenant identifier.
   * @param params.entity The selected entity for the module.
   * @param params.module The selected BOSs module.
   */
  public deleteBossAccess(params: {
    tenantIdentifier: string;
    entity: string;
    module: BossModuleName;
  }): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiBasePath}/v1/boss/tenant-assignment/${params.tenantIdentifier}/${params.entity}/${params.module}`
    );
  }
}
