import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import {
  AutoRefreshCheckboxComponent,
  BannerContainerComponent,
  DialogLayoutComponent,
  ErrorDialogComponent,
  TradeResultDialogComponent,
  FeaturePhaseBannerComponent,
  ViewHeaderComponent,
  AnonymousViewBoxComponent,
  ConfirmationDialogComponent,
  AssignValueDialogComponent,
  SlideToggleComponent,
  InformationDialogComponent,
  HelpDrawerButtonComponent,
  HelpDrawerContainerComponent,
  GenericBannerComponent,
} from './components';
import { TradeHistoryTableComponent } from './components/trade-history-table/trade-history-table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ScheduleStateDetailsPipe, ScheduleStatePipe, ScheduleTypePipe, TradeDirectionDisplayPipe } from './pipes';
import { HupxDamConstraintService, MekiSchedulesService } from './services';
import { SekScheduleTableComponent } from './components/sek-schedule-table/sek-schedule-table.component';
import { SchedulesTableComponent } from './components/schedules-table/schedules-table.component';
import { DomesticSchedulesTableComponent } from './components/domestic-schedules-table/domestic-schedules-table.component';
import { AppThousandSeparatedDirective } from '../../shared/directives';
import { HelpDrawerService } from '../../services';
import { LayoutModule } from '../layout/layout.module';
import { SyncDialogComponent } from './components/sync-dialog/sync-dialog.component';
import { SyncBadgeComponent } from './components/sync-badge/sync-badge.component';
import { AssignTenantBossAccessDialogComponent } from '../tenants/components/assign-tenant-boss-access-dialog/assign-tenant-boss-access-dialog.component';

const DECLARATIONS = [
  AssignValueDialogComponent,
  AutoRefreshCheckboxComponent,
  DeleteConfirmationDialogComponent,
  DialogLayoutComponent,
  ErrorDialogComponent,
  TradeHistoryTableComponent,
  TradeResultDialogComponent,
  BannerContainerComponent,
  ViewHeaderComponent,
  MaintenanceBannerComponent,
  FeaturePhaseBannerComponent,
  AnonymousViewBoxComponent,
  ScheduleStatePipe,
  ScheduleStateDetailsPipe,
  ScheduleTypePipe,
  SekScheduleTableComponent,
  TradeDirectionDisplayPipe,
  ConfirmationDialogComponent,
  SchedulesTableComponent,
  DomesticSchedulesTableComponent,
  AppThousandSeparatedDirective,
  SlideToggleComponent,
  InformationDialogComponent,
  HelpDrawerButtonComponent,
  HelpDrawerContainerComponent,
  SyncDialogComponent,
  SyncBadgeComponent,
  GenericBannerComponent,
  AssignTenantBossAccessDialogComponent,
];

const PROVIDERS = [HupxDamConstraintService, MekiSchedulesService, HelpDrawerService];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, LayoutModule],
  providers: [...PROVIDERS],
  exports: [...DECLARATIONS],
})
export class SharedModule {}
