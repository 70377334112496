<app-dialog-layout class="assign-tenant-boss-access__app-dialog-layout" [closeable]="false">
  <section name="title">Assign access</section>
  <app-banner-container></app-banner-container>
  <form [formGroup]="formGroup">
    <div class="control-row">
      <mat-form-field class="module-selector" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Module</mat-label>
        <mat-select formControlName="module" [disabled]="requestInFlight">
          @for (module of availableBossModuleNames; track module) {
            <mat-option [value]="module">
              {{ module }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="entity-selector" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Entity</mat-label>
        <mat-select formControlName="entity" [disabled]="requestInFlight">
          @for (entity of availableEntities; track entity) {
            <mat-option [value]="entity">
              {{ entity }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="access-selector" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Access level</mat-label>
        <mat-select formControlName="access" [disabled]="requestInFlight">
          @for (access of availableBossModuleAccessLevels; track access) {
            <mat-option [value]="access">
              {{ access }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <section name="footer" class="assign-tenant-boss-access__actions">
    <button mat-button (click)="handleCancelAction()" [disabled]="requestInFlight">Cancel</button>
    <button mat-button color="primary" (click)="handleAcceptAction()" [disabled]="formGroup.invalid">
      Confirm
      @if (requestInFlight) {
        <mat-spinner [diameter]="20"></mat-spinner>
      }
    </button>
  </section>
</app-dialog-layout>
