export enum EdaTab {
  StrategyUp = 'strategyUp',
  StrategyDown = 'strategyDown',
  LimitPriceUp = 'limitPriceUp',
  LimitPriceDown = 'limitPriceDown',
  QuantityThresholdUp = 'quantityThresholdUp',
  QuantityThresholdDown = 'quantityThresholdDown',
  QuantityBlockSizeUp = 'quantityBlockSizeUp',
  QuantityBlockSizeDown = 'quantityBlockSizeDown',
}
