/* eslint-disable @typescript-eslint/naming-convention */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../shared/interfaces';
import { KdaOfferDetailsResponse, ListKdaOfferHeadsResponse, KdaResponseMeta } from '../interfaces/responses';
import { filterOutNullishProperties } from '../../../shared/utils';
import { ListKdaReportsResponse } from '../../../shared/interfaces/responses/list-kda-offer-reports-response.interface';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';

@Injectable({ providedIn: 'root' })
export class KdaOfferService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Loads the meta information about available versions for the given day and entity.
   *
   * @param params.date The selected date.
   * @param params.entity The selected KDA offer entity.
   * @param params.tenantIdentifier The unique identifier of the selected tenant.
   */
  public listKdaOfferHeads(params: {
    date: Date;
    entity: string;
    tenantIdentifier: string;
  }): Observable<ApiResponse<ListKdaOfferHeadsResponse[], KdaResponseMeta>> {
    return this.http.get<ApiResponse<ListKdaOfferHeadsResponse[], KdaResponseMeta>>(
      `${environment.apiBasePath}/v1/kda`,
      {
        params: {
          date: formatInBudapestTimeZone(params.date, 'yyyy-MM-dd'),
          entity: params.entity,
          tenant: params.tenantIdentifier,
        },
      }
    );
  }

  /**
   * Loads the details of the selected KDA offer.
   *
   * @param id The selected KDA offer ID.
   */
  public getKdaOffer(id: number): Observable<KdaOfferDetailsResponse> {
    return this.http
      .get<ApiResponse<KdaOfferDetailsResponse>>(`${environment.apiBasePath}/v1/kda/${id}`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Saves a new KDA offer entity.
   *
   * @param kdaOffer The new KDA offer values.
   * @param latestKnownVersion The latest known version. It is optional.
   */
  public saveKdaOffer(
    kdaOffer: Omit<KdaOfferDetailsResponse, 'id' | 'version' | 'createdAt' | 'createdBy' | 'createdByUser'>,
    latestKnownVersion?: number
  ): Observable<KdaOfferDetailsResponse> {
    return this.http
      .post<ApiResponse<KdaOfferDetailsResponse>>(`${environment.apiBasePath}/v1/kda`, kdaOffer, {
        params: filterOutNullishProperties({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          latest_known_version: latestKnownVersion,
        }),
      })
      .pipe(map(response => response?.payload));
  }

  /**
   * Copies a KDA offer to a selected range.
   *
   * @param id The ID of the selected KDA offer from which we want to copy.
   * @param params It contains the selected start and end date and the latest known version.
   */
  public copyKdaOfferValues(
    id: number,
    params: { fromDate: string; toDate: string; latestKnownVersion: number }
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiBasePath}/v1/kda/copy/${id}`,
      {},
      {
        params: {
          from_date: params.fromDate,
          to_date: params.toDate,
          latest_known_version: params.latestKnownVersion,
        },
      }
    );
  }

  /**
   * Updates the KDA sending state.
   *
   * @param entity The selected KDA entity.
   * @param state The state with which we want to update the current one.
   */
  public sendKdaSendingState(entity: string | undefined, state: boolean): Observable<void> {
    return this.http.post<void>(`${environment.apiBasePath}/v1/kda/sending-state`, { state, entity });
  }

  /**
   * Loads the KDA offer reports for the given date, entity(?) and direction(?).
   *
   * @param params contains the date, the tenant identifier, the direction and the entity query parameters.
   */
  public listKdaOfferReports(params: {
    date: Date;
    tenantIdentifier: string;
    direction?: string;
    entity?: string;
  }): Observable<ApiResponse<ListKdaReportsResponse[], KdaResponseMeta>> {
    return this.http.get<ApiResponse<ListKdaReportsResponse[], KdaResponseMeta>>(
      `${environment.apiBasePath}/v1/kda/report`,
      {
        params: filterOutNullishProperties({
          date: formatInBudapestTimeZone(params.date, 'yyyy-MM-dd'),
          tenant: params.tenantIdentifier,
          direction: params.direction,
          entity: params.entity,
        }),
      }
    );
  }
}
