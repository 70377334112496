import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { BannerSeverity } from '../../enums';
import { ApiErrorResponse, BannerOptions } from '../../interfaces';
import { BehaviorSubject, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-banner-container',
  templateUrl: './banner-container.component.html',
  styleUrls: ['./banner-container.component.scss'],
})
export class BannerContainerComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input()
  public maxItems = 3;

  @Input()
  public messages: { text: string; options: BannerOptions }[] = [];

  /** Responsible for handling close banner events coming from children banners.  */
  public closeClicked$ = new BehaviorSubject<number | null>(null);

  public bannerSeverity = BannerSeverity;

  public ngOnInit() {
    this.closeClicked$
      .pipe(
        tap(index => {
          if (index !== null) {
            this.closeBanner(index);
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public showMessage(messageOrTranslationKey: string, options: BannerOptions): void {
    const bannerOptions = {
      severity: options.severity ?? BannerSeverity.Info,
      priority: options.priority ?? 0,
      closable: options.closable,
      groupingKey: options.groupingKey,
      overwrite: options.overwrite ?? true,
    };

    /** When grouping key is provided and the overwrite parameter is `true`, ... **/
    if (bannerOptions.overwrite && bannerOptions.groupingKey !== undefined) {
      const messagesInSameGroup = this.messages.filter(
        message => message.options.groupingKey === bannerOptions.groupingKey
      );

      /** ... we overwrite text when only a single item is found with the given grouping key */
      if (messagesInSameGroup.length === 1) {
        messagesInSameGroup[0].text = messageOrTranslationKey;

        return;
      }

      /** ... we remove all and add new item when multiple notification with same grouping key presented. */
      this.clearSpecific([bannerOptions.groupingKey]);
    }

    /** If the messages count exceeds the allowed maximum, we remove the lowest priority message. */
    if (this.messages.length + 1 > this.maxItems) {
      this.messages.pop();
    }

    this.messages.push({
      text: messageOrTranslationKey,
      options: bannerOptions,
    });

    /** Priority is more important than severity thus we order by that last. */
    this.messages.sort((a, b) => (b.options.severity as BannerSeverity) - (a.options.severity as BannerSeverity));
    this.messages.sort((a, b) => (b.options.priority as number) - (a.options.priority as number));
  }

  public showInfo(messageOrTranslationKey: string, options: BannerOptions): void {
    this.showMessage(messageOrTranslationKey, {
      ...options,
      severity: BannerSeverity.Info,
    });
  }

  public showWarning(messageOrTranslationKey: string, options: BannerOptions): void {
    this.showMessage(messageOrTranslationKey, {
      ...options,
      severity: BannerSeverity.Warning,
    });
  }

  public showError(messageOrTranslationKey: string, options: BannerOptions): void {
    this.showMessage(messageOrTranslationKey, {
      ...options,
      severity: BannerSeverity.Error,
    });
  }

  public showApiError(errorResponseJson: ApiErrorResponse | Error, options: BannerOptions): void {
    this.showMessage(errorResponseJson?.message || 'An unknown error happened.', {
      ...options,
      severity: BannerSeverity.Error,
    });
  }

  /**
   * Clears all the banners.
   *
   * @param filter Optional parameter to clear only the selected banners.
   */
  public clearAll(filter?: {
    /** The severity of banners to clear. */
    type: BannerSeverity;
    /** Optional array of banner grouping keys to keep. */
    keptGroupingKeys?: string[];
  }): void {
    if (filter === undefined) {
      this.messages = [];

      return;
    }

    this.messages = this.messages.filter(
      message =>
        message.options.severity !== filter.type ||
        (message.options.groupingKey !== undefined && filter.keptGroupingKeys?.includes(message.options.groupingKey))
    );
  }

  /**
   * Clears the banners with the specified grouping keys.
   *
   * @param groupingKeys The grouping key of banners to clear.
   */
  public clearSpecific(groupingKeys: string[]): void {
    this.messages = this.messages.filter(
      message => message.options.groupingKey === undefined || !groupingKeys.includes(message.options.groupingKey)
    );
  }

  public closeBanner(index: number): void {
    this.messages = [...this.messages.slice(0, index), ...this.messages.slice(index + 1)];
  }
}
