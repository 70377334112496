import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../../../shared/interfaces';
import { ListRirMarketHeadsResponse, RirMarketDetailsResponse, RirResponseMeta } from '../interfaces/responses';
import { environment } from '../../../../environments/environment';
import { filterOutNullishProperties } from '../../../shared/utils';
import { RirPodResponsePayload } from '../interfaces/responses/rir-pods-response.interface';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';

@Injectable({ providedIn: 'root' })
export class RirMarketService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Loads the meta information about available versions for the given month and podCode.
   *
   * @param date The selected month.
   * @param podCode The unique identifier of the related POD.
   */
  public listRirMarketHeads(
    date: Date,
    podCode: string
  ): Observable<ApiResponse<ListRirMarketHeadsResponse[], RirResponseMeta>> {
    return this.http.get<ApiResponse<ListRirMarketHeadsResponse[], RirResponseMeta>>(
      `${environment.apiBasePath}/v1/rir`,
      {
        params: {
          date: formatInBudapestTimeZone(date, 'yyyy-MM-dd'),
          pod_code: podCode,
        },
      }
    );
  }

  /**
   * Loads the details of the selected RIR market.
   *
   * @param id The selected RIR market ID.
   */
  public getRirMarketHead(id: number): Observable<RirMarketDetailsResponse> {
    return this.http
      .get<ApiResponse<RirMarketDetailsResponse>>(`${environment.apiBasePath}/v1/rir/${id}`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Lists available RIR PODs.
   *
   * @param date The start of the month for getting available RIR pods. Must be in yyyy-MM-01 format,
   * because availability need to be represented by month.
   */
  public listAvailableRirPods(date: string): Observable<RirPodResponsePayload[]> {
    return this.http
      .get<ApiResponse<RirPodResponsePayload[]>>(`${environment.apiBasePath}/v1/platform/master-data/rir-pods`, {
        params: { date },
      })
      .pipe(map(response => response?.payload));
  }

  /**
   * Saves a new RIR market entity.
   *
   * @param rirMarket The new RIR market values.
   * @param latestKnownVersion The latest known version. It is optional.
   */
  public saveRirMarket(
    rirMarket: Omit<RirMarketDetailsResponse, 'id' | 'version' | 'createdAt' | 'createdBy' | 'createdByUser'>,
    latestKnownVersion?: number
  ): Observable<RirMarketDetailsResponse> {
    return this.http
      .post<ApiResponse<RirMarketDetailsResponse>>(`${environment.apiBasePath}/v1/rir`, rirMarket, {
        params: filterOutNullishProperties({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          latest_known_version: latestKnownVersion,
        }),
      })
      .pipe(map(response => response?.payload));
  }

  /**
   * Updates the RIR sending state.
   *
   * @param state The state with which we want to update the current one.
   */
  public sendRirSendingState(state: boolean): Observable<void> {
    return this.http.post<void>(`${environment.apiBasePath}/v1/rir/sending-state`, { state });
  }
}
