/* eslint-disable @typescript-eslint/naming-convention */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CapacityTypeEnum, RszEntityEnum, TenderTypeEnum } from '../enums';
import { ApiResponse } from '../../../shared/interfaces';
import { environment } from '../../../../environments/environment';
import {
  RszTenderPricingHeadsResponse,
  RszTenderPricingResponse,
  RszTenderReportsResponse,
} from '../interfaces/responses';
import { Observable, map } from 'rxjs';
import { SaveRszTenderPricing } from '../interfaces/requests';
import { filterOutNullishProperties } from '../../../shared/utils';
import { RszResponseMeta } from '../interfaces/responses/rsz-response-meta.interface';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';

@Injectable()
export class RszTenderPricingService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Lists RSZ tender pricing heads.
   *
   * @param params.date The date of the RSZ tender pricing.
   * @param params.tenderType It can be only `intraday`.
   * @param params.capacityType It can be `frrPositive` or `frrNegative`.
   * @param params.entity It can be `ASZK` or `ASZKM1`.
   * @param params.tenant The unique identifier of the selected tenant.
   */
  public listRszTenderPricingHeads(params: {
    date: Date;
    tenderType: TenderTypeEnum;
    capacityType: CapacityTypeEnum;
    entity: RszEntityEnum;
    tenant: string;
  }): Observable<ApiResponse<RszTenderPricingHeadsResponse[], RszResponseMeta>> {
    return this.http.get<ApiResponse<RszTenderPricingHeadsResponse[], RszResponseMeta>>(
      `${environment.apiBasePath}/v1/rsz`,
      {
        params: filterOutNullishProperties({
          date: formatInBudapestTimeZone(params.date, 'yyyy-MM-dd'),
          tender_type: params.tenderType,
          capacity_type: params.capacityType,
          entity: params.entity,
          tenant: params.tenant,
        }),
      }
    );
  }

  /**
   * Gets an RSZ tender pricing by ID.
   *
   * @param id The ID of the RSZ tender pricing.
   */
  public getRszTenderPricing(id: number): Observable<RszTenderPricingResponse> {
    return this.http
      .get<ApiResponse<RszTenderPricingResponse>>(`${environment.apiBasePath}/v1/rsz/${id}`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Saves the selected RSZ tender pricing.
   *
   * @param rszTenderPricing An RSZ tender pricing object.
   * @param latestKnownVersion The latest known version of the RSZ tender pricing.
   *
   * @returns With a detailed RSZ tender pricing object.
   */
  public saveRszTenderPricing(
    rszTenderPricing: SaveRszTenderPricing,
    latestKnownVersion?: number
  ): Observable<RszTenderPricingResponse> {
    return this.http
      .post<ApiResponse<RszTenderPricingResponse>>(`${environment.apiBasePath}/v1/rsz`, rszTenderPricing, {
        params: filterOutNullishProperties({
          latest_known_version: latestKnownVersion,
        }),
      })
      .pipe(map(response => response?.payload));
  }

  /**
   * Updates the RSZ sending state.
   *
   * @param entity The selected RSZ entity.
   * @param state The state with which we want to update the current one.
   */
  public sendRszSendingState(entity: string | undefined, state: boolean): Observable<void> {
    return this.http.post<void>(`${environment.apiBasePath}/v1/rsz/sending-state`, { state, entity });
  }

  /**
   * Lists the RSZ tender reports by the given parameters.
   *
   * @param params Contains the `date`, the `tenderType`, the `capacityType` and the `tenantIdentifier`.
   */
  public listRszTenderReports(params: {
    date: Date;
    tenderType: TenderTypeEnum;
    capacityType: CapacityTypeEnum;
    tenantIdentifier: string;
  }): Observable<ApiResponse<RszTenderReportsResponse[], RszResponseMeta>> {
    return this.http.get<ApiResponse<RszTenderReportsResponse[], RszResponseMeta>>(
      `${environment.apiBasePath}/v1/rsz/report`,
      {
        params: {
          date: formatInBudapestTimeZone(params.date, 'yyyy-MM-dd'),
          tender_type: params.tenderType,
          capacity_type: params.capacityType,
          tenant: params.tenantIdentifier,
        },
      }
    );
  }
}
